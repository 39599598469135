export const environment = {
    production: true,
    identityApiEndpoint: 'https://digitalchannels-apig.banbif.com.pe/pva/api/identity/connect/token',
    loanSimulationApiEndpoint: 'https://digitalchannels-apig.banbif.com.pe/pva/api',
    customerApiEndpoint: 'https://digitalchannels-apig.banbif.com.pe/pva/api',
    optionalInsurancesApiEndpoint: 'https://digitalchannels-apig.banbif.com.pe/pva/api',
    parametersApiEndpoint: 'https://digitalchannels-apig.banbif.com.pe/pva/api',
    applicationApiEndpoint: 'https://digitalchannels-apig.banbif.com.pe/pva/api',
    offersApiEndpoint: 'https://digitalchannels-apig.banbif.com.pe/pva/api',
    customerAccountApiEndpoint: 'https://digitalchannels-apig.banbif.com.pe/pva/api',
    reCaptchaKey: "6Lcb3ZkqAAAAAAfD_mOJDrcd3UPIXa6O1sWf40A0"
};